import React, {useState} from "react";
import {Link} from "react-router-dom";
import {extractResponseValidationErrors, truncate} from "../../utils";
import {getUserFullName} from "../../utils/index";
import MyOpinionLogo from "../../assets/images/MyOpinion_Logo_Head.png";
import httpService from "../../services/http.service";
import {useRouteMatch} from 'react-router-dom';
import * as Yup from 'yup';
import {useFormik} from "formik";
import useAlert from "../../hooks/useAlert";
import {Col, Form, FormGroup} from "reactstrap";
import AppAlert from "./AppAlert";
import AppInput from "./AppInput";
import AppCheckbox from "./AppCheckbox";
import AppDatepicker from "./AppDatePicker";
import {serialize} from "object-to-formdata";

const LBL_OPINIONS_PRIV = process.env.REACT_APP_SOCIAL_HUB ? 'Contacts' : 'Potes';
const LBL_OPINIONS_PUB = process.env.REACT_APP_SOCIAL_HUB ? (process.env.REACT_APP_PUBLIC_FEED_NAME || 'Public') : 'Public';

const AppOpinion = ({
                        user,
                        opinion: data,
                        toggleMute = () => null,
                        shouldTruncate = true,
                        truncateLength = 200,
                        onBoost = () => null,
                        onRemoveBoost = () => null,
                        ...props
                    }) => {

    const initialValues = {
        boosting_opinion_id: '',
        boosting_ended_at: '',
    };

    const routeMatch = useRouteMatch();

    const [opinion, setOpinion] = useState(data);
    const [alert, setAlert, onClose] = useAlert();
    const [submitting, setSubmitting] = useState(false);
    const [modal, setModal] = useState({
        boost: false
    });

    const formatOpinionAttachments = (obj) => {

        const attachments = [];

        if(obj['sond_type'] && obj['sond_picture']) {
            attachments.push({
                attach_url: obj['sond_picture'],
                attach_type: obj['sond_type'],
                attach_thumbnail: obj['sond_thumbnail'],
            });
        }

        (obj?.attachments || []).forEach(attach => attachments.push({
            attach_url: attach['attach_url'],
            attach_type: attach['attach_type'],
            attach_thumbnail: attach['attach_thumbnail'],
        }));

        return attachments;

    }

    const onSubmit = async (values) => {

        setSubmitting(true);
        setAlert(null);

        const formData = serialize(values, {
            indices: true,
            booleansAsIntegers: true
        });

        try {

            const {data: response} = await httpService.post(`/boosting`, formData);
            onCancelBoost();
            onRemoveBoost();
            /*
            const obj = response?.data;
            obj['attachments'] = formatOpinionAttachments(obj);
            setOpinion(state => ({...state, ...obj}));
             */
        }
        catch ({response}) {
            const {data, status} = response;

            if(response && status === 422) {
                setAlert({
                    type: 'danger',
                    message: extractResponseValidationErrors(data)[0]
                })
            }
            else if(response && status === 500) {
                setAlert({
                    type: 'danger',
                    message: 'Erreur de traitement, veuillez contacter les administrateurs'
                })
            }
        }
        finally {
            setSubmitting(false);
        }

    }

    const onCancelBoost = () => {
        formik.resetForm({...initialValues});
        setAlert(null);
        setModal(state => ({
            ...state,
            boost: false
        }));
    }

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({
            boosting_opinion_id: Yup.string().ensure().required('Champ obligatoire'),
            boosting_ended_at: Yup.string().ensure().required('Champ obligatoire'),
        }),
        onSubmit
    });

    const handleBoost = async (opinion) => {

        formik.setFieldValue('boosting_opinion_id', opinion?.sond_id);

        setModal(state => ({
            ...state,
            boost: true
        }));
    }

    const handleRemoveBoost = async (opinion) => {
        if(!window.confirm('Supprimer le boost \nConfirmer ?')) return;

        try {
            const { data: response } = await httpService.delete(`/opinions/${opinion.sond_id}/removeBoostings`);
            onRemoveBoost();
            /*
            const obj = response?.data;
            obj['attachments'] = formatOpinionAttachments(obj);
            setOpinion(state => ({...state, ...obj}));
            */
        } catch (e) {
            throw e;
        }

    }

    const handleToggle = async (opinion) => {
        if(!window.confirm('Confirmer ?')) return;

        try {
            const { data: response } = await httpService.put(`/opinions/${opinion.sond_id}/toggleMute`);
            setOpinion(opinion => ({
                ...opinion,
                sond_mute: !opinion.sond_mute
            }));
        } catch (e) {
            throw e;
        }
    }

    return (
        <div className="card mb-1" key={opinion?.sond_id}>
            <div className="body">
                <blockquote className="blockquote mb-0">
                    <footer className="blockquote-footer mb-1">
                        <Link to={`/users/${user?.user_username}`}>
                            <small className="text-muted font-weight-bold" style={{cursor: "pointer"}}>
                                <img
                                    className="avatar sm rounded-circle mr-1"
                                    src={user?.profile?.prof_picture || MyOpinionLogo}
                                    alt="avatar"
                                />
                                {getUserFullName(user, true)}
                            </small>
                        </Link>
                        <small className="float-right text-muted">
                            {opinion?.boostings_count > 0 && (
                                <>
                                    <span title={"Supprimez le boost"} className="badge badge-success" onClick={() => handleRemoveBoost(opinion)} style={{cursor: "pointer"}}>
                                <span>Boost</span>{' '}
                                        <i className="fa fa-bullhorn"></i>
                            </span>{' '}
                                </>
                            )}
                            {opinion?.sond_deleted_at && (
                                <>
                                <span className="badge badge-danger" style={{cursor: "pointer"}}>
                                <span>{process.env.REACT_APP_SOCIAL_HUB ? 'Post supprimé' : 'Opinion supprimée'}</span>{' '}
                                    <i className="fa fa-ban"></i>
                                </span>
                                </>
                            )}
                            {opinion.sond_created_at}
                        </small>
                    </footer>
                    <p>
                        {opinion.agora && (
                            <Link to={`/agoras/${opinion.sond_group}/members`}>
                                <span className="badge badge-success mr-3" style={{cursor: "pointer"}}>
                                {opinion.agora.ago_title}
                            </span>
                                <br/>
                            </Link>
                        )}

                        <i className="fa fa-quote-left"></i>
                        {opinion.sond_caption
                            ? (shouldTruncate ? truncate(opinion.sond_caption, truncateLength) : opinion.sond_caption)
                            : '-'}
                        <br/>
                        <div id="lightgallery" className="row clearfix lightGallery">
                            {(opinion?.attachments || []).map(attachment => (
                                <div className="col-lg-3 col-md-6 m-b-30 m-r-5" key={attachment?.attach_id}>
                                    <a className="light-link" href={attachment?.attach_url} target="_blank">
                                        <img className="img-fluid rounded" src={attachment.attach_thumbnail || attachment?.attach_url} alt="" />
                                    </a>
                                </div>
                            ))}
                        </div>

                    </p>
                    <nav className="d-flex text-muted font-weight-bold">
                        {!opinion.sond_diffusion ? (
                            <span className="icon mr-3" style={{cursor: "pointer"}} title={LBL_OPINIONS_PUB}>
                                                <i className="fa fa-globe"></i>{' '}{LBL_OPINIONS_PUB}
                                            </span>
                        ) : (
                            <span className="icon mr-3" style={{cursor: "pointer"}} title={LBL_OPINIONS_PRIV}>
                                                <i className="fa fa-users"></i>{' '}{LBL_OPINIONS_PRIV}
                                            </span>
                        )}
                        <span className="icon mr-3" style={{cursor: "pointer"}} title="Commentaires">
                                                <i className="fa fa-comments"></i>{' '}{opinion.comments_count || 0}
                                            </span>
                        <span className="icon mr-3" style={{cursor: "pointer"}} title="Soutiens">
                                                <i className="fa fa-heart"></i>{' '}{opinion.soutiens_count || 0}
                                            </span>
                        <span className="icon mr-3" style={{cursor: "pointer"}} title="Partages">
                                                <i className="fa fa-repeat"></i>{' '}{opinion.shares_count || 0}
                                            </span>
                        <span className="icon mr-3 text-warning" title="Signalement" style={{cursor: "pointer"}}>
                                                <i className="fa fa-warning"></i>{' '}{opinion.reports_count || 0}
                                            </span>

                        {!opinion?.sond_deleted_at && (
                            <>
                                {opinion.sond_mute ? (
                                    <span className="icon mr-3 text-success" onClick={() => handleToggle(opinion)} style={{cursor: 'pointer'}}>
                                                <i className="fa fa-volume-up"></i>{' '}Unmute
                                            </span>
                                ) : (
                                    <span className="icon mr-3 text-danger" onClick={() => handleToggle(opinion)} style={{cursor: 'pointer'}}>
                                                <i className="fa fa-volume-off"></i>{' '}Mute
                                            </span>
                                )}

                                {opinion?.boostings_count === 0 ? (
                                    <>
                                <span className="icon mr-3 text-green" onClick={() => handleBoost(opinion)} style={{cursor: 'pointer'}}>
                                                <i className="fa fa-bullhorn"></i>{' '}Booster
                                        </span>
                                    </>
                                ) : (
                                    <>
                                <span className="icon mr-3 text-warning" onClick={() => handleRemoveBoost(opinion)} style={{cursor: 'pointer'}}>
                                                <i className="fa fa-minus-square"></i>{' '}Supprimer le boost
                                        </span>
                                    </>
                                )}
                            </>
                        )}



                        {routeMatch.url !== `/opinions/${opinion.sond_id}` && (
                            <>
                                <Link className="text-muted ml-auto" to={`/opinions/${opinion.sond_id}`}>
                                    <i className="fa fa-eye"></i>{' '} {process.env.REACT_APP_SOCIAL_HUB ? 'Voir le post' : 'Voir l\'opinion'}
                                </Link>
                            </>
                        )}
                    </nav>
                </blockquote>
            </div>

            <div className={`modal fade ${modal.boost ? 'd-block show' : ''}`} id="exampleModal"  onClick={() => null}>
                <div className="modal-dialog modal-md" role="document">
                    <Form onSubmit={formik.handleSubmit}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    {process.env.REACT_APP_SOCIAL_HUB ? 'Booster le post' : 'Booster l\'opinion'}
                                </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCancelBoost}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {alert && <AppAlert onClose={onClose} type={alert.type}>{alert.message}</AppAlert>}

                                <FormGroup>
                                    <AppInput
                                        name={"boosting_opinion_id"}
                                        error={formik.errors.boosting_opinion_id}
                                        touched={formik.touched.boosting_opinion_id}
                                        value={`#${opinion?.sond_id} - ${opinion?.sond_caption}`}
                                        disabled
                                    />
                                    <AppDatepicker
                                        className="form-control"
                                        label={"Date de fin"}
                                        value={formik.values.boosting_ended_at}
                                        error={formik.errors.boosting_ended_at}
                                        touched={formik.touched.boosting_ended_at}
                                        onChange={(date) => formik.setFieldValue('boosting_ended_at', date)}
                                    />
                                </FormGroup>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className={`btn btn-round btn-danger font-weight-bold`} disabled={submitting} data-dismiss="modal" onClick={onCancelBoost}>
                                    <span className="fa fa-times"></span>{' '}Annuler
                                </button>
                                <button type="submit" className={`btn btn-round btn-success font-weight-bold`} disabled={submitting}>
                                    <span className="fa fa-save"></span>{' '}{submitting ? 'Enregistrement...' : 'Enregistrer'}
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default AppOpinion;